import './ProfelInfo.css';
import ava from './../../assets/vod.png';
import { useState, useEffect } from "react";
import axios from "axios";
import Navbar from '../../companents/navbar/Navbar';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useContext } from 'react';
import AppContext from '../../context/AppContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ProfelInfo() {
  const {setNatfecation} = useContext(AppContext)
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [userInfos, setUserInfos] = useState([]);

  const userID = localStorage.getItem('userID');
  const tokenw = localStorage.getItem('accessToken');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Foydalanuvchi ma'lumotlarini olish uchun so'rovni yuborish
    const getUserInfo = async () => {
      try {
        const response = await axios.get(`https://manager.zafarr.uz/routers/userprofile/${userID}/`, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Token ${tokenw}`,
          },
        });
        setUserInfos(response.data);
        setImageSrc(response.data[0].image_url);
      } catch (error) {
        // console.error('Foydalanuvchi ma\'lumotlarini olishda xatolik:', error);
      }
    };

    getUserInfo();
  }, [userID, tokenw]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageUrl = event.target.result;
        setSelectedFile(file);
        setImageSrc(imageUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userID');
    localStorage.removeItem('is_admin');
    localStorage.removeItem('secure_is_admin');
    window.location.reload();
  };

  const imageChange = async () => {
    try {
      const formData = new FormData();
      formData.append('username', userInfos[0].username);
      formData.append('email', userInfos[0].email);
      formData.append('profile_image', selectedFile);

      const response = await axios.put(
        `https://manager.zafarr.uz/userprofiles/${userID}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": `Token ${tokenw}`,
          },
        }
      );
      console.log('Rasm o\'zgartirildi:', response.data);
      // Sahifani qayta yuklang
      window.location.reload();
    } catch (error) {
      console.error("Rasm o'zgartirishda xatolik:", error);
    }
  };

  // input type password || text 

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // password change
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const changePassword = async () => {
    try {
      const response = await axios.post(
        `https://manager.zafarr.uz/change_password/${userID}/`,
        {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${tokenw}`,
          },
        }
      );
      console.log('Password changed:', response.data);
      handleClose(); // Close the modal after successful password change
      // You can also display a success message or perform any other action here
      handleLogout()
      setNatfecation("Parol O'zgardi");
      setTimeout(() => {
          setNatfecation("")
      }, 1800);
    } catch (error) {
      console.error('Error changing password:', error);
      setNatfecation("Parol O'zgartirishda Xatolik");
      setTimeout(() => {
          setNatfecation("")
      }, 1800);
      // You can display an error message to the user here
    }
  };
  
  return (
    <div className="ProfelInfo">
      <div className="PersolalContainer">
        <div className="PersonalTitle">
          <h1>Men haqimda</h1>
        </div>
        {userInfos.map((item) => (
          <div className="PersonalInfoBox" key={item.id}>
            <div className="PersonalPhoto">
              <div className="NamePhoto">
                <p>PHOTO</p>
              </div>
              <div className="ImgPhoto">
                <div className="ImgPhotoBox">
                  <img src={imageSrc || item.image_url} alt="User's photo" />
                </div>
              </div>
              <div className="InputPhoto">
                <div>
                  <label htmlFor="fileInput" className="custom-file-upload">Image</label>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                </div>
              </div>
            </div>
            <div className="PersonalName">
              <div className="NameName">
                <p>NAME</p>
              </div>
              <div className="NameText">
                <p>{item.first_name} {item.last_name}</p>
              </div>
            </div>
            <div className="PersonalAdress">
              <div className="AdressName">
                <p>ADDRESS</p>
              </div>
              <div className="AdressEmail">
                <p>{item.email}</p>
              </div>
            </div>
            <div className="PersonalAdress">
              <div className="AdressName">
                <p>Password</p>
              </div>
              <div className="AdressEmail">
                <button className='changePasBtn' onClick={handleOpen}>Password Change</button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      <div className="pasChange__inputs">
                        <div className="pasIn">
                          <input id="oldPassword" type="text" placeholder='Eski Parol' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}/>
                          <button type="button">
                          </button>
                        </div>
                        <div className="pasIn">
                          <input id="newPassword" type={!showPassword1 ? "password" : "text"} placeholder='Yangi Parol' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                          <button type="button" onClick={() => showPassword1 ? setShowPassword1(false) : setShowPassword1(true)}>
                            {showPassword1 ? <FaRegEye /> : <FaRegEyeSlash />}
                          </button>
                        </div>
                        <div className="pasIn">
                          <input id="confirmPassword" type={!showPassword2 ? "password" : "text"} placeholder='Yangi Parolni Takrorlang' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                          <button type="button" onClick={() => showPassword2 ? setShowPassword2(false) : setShowPassword2(true)}>
                            {showPassword2 ? <FaRegEye /> : <FaRegEyeSlash />}
                          </button>
                        </div>
                      </div>
                      <button id='chanPass' onClick={changePassword}>O'zgartirish</button>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        ))}
        <div className="LogAut">
          <button onClick={handleLogout}>LogOut</button>
          <button onClick={imageChange}>Saqlash</button>
        </div>
      </div>
    </div>
  );
}
