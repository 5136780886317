import { useState } from 'react';
import OtdelUser from '../otedelUsers/OtdelUser';
import './mainCard.css';

export default function OtdelCard({ item }) {
    const [modalOpen, setModalOpen] = useState(false); // Modal ochish/hide bo'oleani

    const openModal = () => {
        setModalOpen(true); // Modalni ochish
    };

    const closeModal = () => {
        setModalOpen(false); // Modalni yopish
    };

    return (
        <>
            <div onClick={openModal} className="mainCard" key={item.id}> 
                <div className="odelCardTitle">
                    <p>Bolim Nomi:</p>
                    <h4>{item.title}</h4>
                </div>
            </div>
            {modalOpen && <OtdelUser item={item} closeModal={closeModal} />} {/* Agar modalOpen true bo'lsa, OtdelUser komponentini ochiramiz */}
        </>
    );
}
