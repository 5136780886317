import './usernav.css'
import { FaSearch } from "react-icons/fa";

export default function UserNav({text , handleSearch}) {
    return (
        <div className="userNav">
            <h5>{text}</h5>
            <label htmlFor="search">
            <input type="search" placeholder='Otdel yoki User ismini qiriting' onChange={handleSearch} />
                <button><FaSearch /></button>
            </label>
        </div>
    )
}