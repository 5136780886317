import { useContext } from 'react'
import './massages.css'
import AppContext from '../context/AppContext'


export default function Massages({notfecation}) {

    // const {notfecation} = useContext(AppContext)

    return (
        <div className="Natvecation">
            <p>{notfecation}</p>
        </div>
    )
}
