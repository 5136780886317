import React, { useState } from 'react';
import './selectuser.css';

function SelectUser({ userData, setUserIds , userIds , userID}) {
    const [searchText, setSearchText] = React.useState('');
    const [selected, setSelected] = React.useState([]);

    console.log(userIds);

    const onSelect = (id) => {
        let updatedSelected = [...selected];
        if (!updatedSelected.includes(id)) {
            updatedSelected.push(id);
            setUserIds([...userIds, id]); // userIds ni yangilash
        } else {
            updatedSelected = updatedSelected.filter(item => item !== id);
            setUserIds(userIds.filter(userId => userId !== id)); // userIds ni yangilash
        }
        setSelected(updatedSelected);
    };

    const isSelected = (id) => {
        return selected.includes(id);
    };

    const onSearch = (text) => {
        setSearchText(text.toLowerCase());
    };

    const filterByText = (list, searchText) => {
        return list.filter(item => item.first_name.toLowerCase().includes(searchText));
    };

    const filteredList = searchText ? filterByText(userData, searchText) : userData;

    return (
        <div id="app">
            <div id="app-content">
                <h3>Qoshish</h3>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={(e) => onSearch(e.target.value)} placeholder="Search Members" />
                </div>
                <ul className="user-list">
                    {filteredList.map((item, index) => (
                        <li key={index} style={{ position: 'relative' }} onClick={() => onSelect(item.id)}>
                            <div className="user-image"><img src={item.image_url} alt="" /></div>
                            <div className="user-data">
                                <h4>{item.first_name}</h4>
                                <span>{item.last_name}</span>
                            </div>
                            <label className="round-checkbox">
                                <span className={"checkmark " + (isSelected(item.id) ? 'checked' : '')}></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default SelectUser;
