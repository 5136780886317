import React from 'react';
import './selectuser.css';

function SelectOtdel({otedelData}) {
    const [searchText, setSearchText] = React.useState('');
    const [selected, setSelected] = React.useState([]);

    const users = [
        {
            "id": 1,
            "first_name": "Glenn",
            "mobile": true,
            "email": "derrick@studiod.com",
            "date": "22-Mar-2016",
            "time": "5:46 PM",
            "image": "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            "id": 2,
            "first_name": "Carl",
            "mobile": false,
            "email": "derrick@studiod.com",
            "date": "22-Feb-2016",
            "time": "09:38 PM",
            "image": "https://randomuser.me/api/portraits/women/37.jpg"
        },
        // Ma'lumotlarni davom ettirish
    ];

    const onSelect = (id) => {
        let updatedSelected = [...selected];
        if (!updatedSelected.includes(id)) {
            updatedSelected.push(id);
        } else {
            updatedSelected = updatedSelected.filter(item => item !== id);
        }
        setSelected(updatedSelected);
    };

    const isSelected = (id) => {
        return selected.includes(id);
    };

    const onSearch = (text) => {
        setSearchText(text.toLowerCase());
    };

    const filterByText = (list, searchText) => {
        return list.filter(item => item.title.toLowerCase().includes(searchText));
    };

    const filteredList = searchText ? filterByText(otedelData, searchText) : otedelData;

    return (
        <div id="app">
            <div id="app-content">
                <h3>Bolim Qoshish</h3>
                <div style={{ position: 'relative' }}>
                    <input type="text" onChange={(e) => onSearch(e.target.value)} placeholder="Qidirish" />
                </div>
                <ul className="user-list">
                    {filteredList.map((item, index) => (
                        <li key={index} style={{ position: 'relative' , transform: `translateX(${-50}px)`}} onClick={() => onSelect(item.id)}>
                            {/* <div className="user-image"><img src={item.image} alt="" /></div> */}
                            <div className="user-data">
                                <h4>{item.title}</h4>
                                {/* <span>{item.email}</span> */}
                            </div>
                            <label className="round-checkbox">
                                <span style={{transform: `translateX(${20}px)`}} className={"checkmark " + (isSelected(item.id) ? 'checked' : '')}></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div> 
        </div>
    );
}

export default SelectOtdel;
