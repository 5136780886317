import axios from 'axios';
import './taskCard.css';
import { Link } from 'react-router-dom';
import { BiPencil } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import BasicModal from '../BasicModal/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { BiCheckboxMinus } from 'react-icons/bi';
import Button from '@mui/material/Button';
import { useRef } from 'react';
import { GoKebabHorizontal } from 'react-icons/go';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { FaUsers } from "react-icons/fa";

export default function TaskCard({ item }) {
  const token = localStorage.getItem('accessToken');

  const deleteBoard = async () => {
    const response = await axios.delete(`https://manager.zafarr.uz/routers/boards/${item.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
    });
    window.location.reload();
  };

  const userID = localStorage.getItem('userID');
  const renameBoard = async (Id, Title) => {
    const response = await axios.put(
      `https://manager.zafarr.uz/routers/all/boards/${item.id}/`,
      {
        title: Title,
        user: userID,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      }
    );
    window.location.reload();
  };

  const redCardAdd = async (id) => {
    const response = await axios.post(
      `https://manager.zafarr.uz/to/${id}/bajarilmagan/`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      }
    );
    window.location.reload();
  };

  const GreenCardAdd = async (id) => {
    const response = await axios.post(
      `https://manager.zafarr.uz/to/${id}/bajarilganga/`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      }
    );
    window.location.reload();
  };

  const [inputDate, setInputDate] = useState('');
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  let interval;

  useEffect(() => {
    const fetchEndDate = async () => {
      try {
        const response = await axios.get(`https://manager.zafarr.uz/routers/boards/${item.id}`);
        const endDate = response.data.end_date; // Assuming the API response contains the end_date field
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const remainingTime = Math.max(0, endDate - currentTime);

        setSeconds(remainingTime);
        setIsRunning(true);
      } catch (error) {
        if (error.response.status === 404) {
          // Handle 404 error
          console.error('Resource not found:', error.response);
        } else {
          // Handle other errors
          console.error('Error:', error);
        }
      }
    };

    fetchEndDate();

    // if (isRunning) {
    //     interval = setInterval(() => {
    //         if (seconds > 0) {
    //             setSeconds(seconds - 1);
    //         }
    //     }, 1000);
    // } else {
    //     clearInterval(`interval`);
    // }

    // return () => {
    //     clearInterval(interval);
    // };
  }, [item.id]);

  const boardValue = useRef();
  const CountdownTimer = ({ endDateString }) => {
    const endDate = new Date(endDateString);
    const [remainingTime, setRemainingTime] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    useEffect(() => {
      const updateRemainingTime = () => {
        const currentTime = new Date();
        const timeDifference = endDate - currentTime;

        if (timeDifference <= 0) {
          clearInterval(intervalId);
          setRemainingTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });

          // Check if all values are zero
          if (remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes === 0 && remainingTime.seconds === 0) {
            // Call the redCardAdd function
            redCardAdd(item.id);
          }
        } else {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

          setRemainingTime({ days, hours, minutes, seconds });
        }
      };


      const intervalId = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(intervalId);
    }, [endDate]);

    const [boardUsers, setBoardUsers] = useState([])
    const BoardUsers = async () => {
      try {
        const response = await axios.get(`https://manager.zafarr.uz/routers/boards/${item.id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Token ${token}`,
            },
          }
        )
        setBoardUsers(response.data.user)
      } catch {
        // console.log("Error:" , error);
      }
    }

    useEffect(() => {
      BoardUsers();
    }, []);

    return (
      <div className="UserCardBox" key={item.id}>
        <div className="UserCard">
          <div className="boximgtype">
            <div className="modal">
              <div className="dropdown">
                <button className="dropbtn"><GoKebabHorizontal /></button>
                <div className="dropdown-content" >
                  <Button onClick={() => redCardAdd(item.id)} style={{ minWidth: 'auto' }} variant="text"><BiCheckboxMinus color='red' size={'30px'} /></Button>

                  <Button onClick={() => GreenCardAdd(item.id)} style={{ minWidth: 'auto' }} variant="text"><BsCheckLg color='green' size={'30px'} /></Button>

                  <BasicModal main={<button className='saveModal' onClick={deleteBoard}>Delete</button>} text={'Delete Board ?'} btn={<AiOutlineDelete color='red' size={'25px'} />} />

                  <BasicModal
                    element={<input onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        renameBoard(item.id, e.target.value)
                      }
                    }} className='modalIn' ref={boardValue} placeholder='Board Name' />}
                    main={<button className='saveModal' onClick={(e) => {
                      renameBoard(item.id, boardValue.current.value)
                    }}>ok</button>} text={'Board rename'}
                    btn={<BiPencil color='black' size={'25px'} />}
                  />
                </div>
              </div>
              <div className="TaskTimer">
                <p>
                  {remainingTime.days} : {remainingTime.hours} : {remainingTime.minutes} : {remainingTime.seconds}
                </p>
                <p>
                  qoldi
                </p>
              </div>
            </div>
          </div>
          <Link to={`/TaskInfo/${item.id}`}>
            <div className="Teaxt">
              <p>{item.title}</p>
            </div>
          </Link>
          <details className="dropdownTask">
            <summary role="button">
              <a className="buttonTask"><FaUsers /></a>
            </summary>
            <ul className='0'>
              {boardUsers.map(item => (
                <li key={item.id}>
                  <div className='USERSimG'>
                    <img src={item.image_url} alt="" />
                  </div>
                  <div className='TastCardUsersName'>
                    <h4>{item.full_name}</h4>
                    <p>{item.kasbi}</p>
                  </div>
                </li>
              ))}
            </ul>
          </details>
        </div>
      </div>
    );
  };

  return (
    <CountdownTimer endDateString={item.end_date} />
  );
}
