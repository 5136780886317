import './nav.scss';
import rmIcon from '../../assets/rm.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { MdAttachFile } from "react-icons/md";
import './raportModal.css';
import { CiFileOn } from "react-icons/ci";
import AppContext from '../../context/AppContext';

// Define PostRaport component outside of Navbar
const PostRaport = ({ token, userInfos }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('Faylni tanlang');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : 'Faylni tanlang');
    };

    const {setNatfecation} = useContext(AppContext)

    const handleSubmit = async () => {
        try {
            if (!file) {
                console.error("Fayl tanlanmagan!");
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post('https://manager.zafarr.uz/routers/raports/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}`,
                },
            });

            console.log('File uploaded successfully:', response.data);
            // setNatfecation("Raport Yuborildi");
            // setTimeout(() => {
            //     setNatfecation("")
            // }, 1800);
            window.location.reload()
        } catch (error) {
            console.error('Error uploading file:', error);
            // setNatfecation("Raport Yubotishda Xatolik!");
            // setTimeout(() => {
            //     setNatfecation("")
            // }, 1800);
        }
    };

    const [classnam , setClassnam] = useState(false)

    return (
        <div className="Raport">
            <div className="Modalcontainer">
                <div className="RaportModalbutton" onClick={() => setClassnam(true)}>
                    <p>Raport</p>
                </div>
                <div onClick={() => setClassnam(false)} className={classnam ? "cancelRaport" : "fixed"}></div>
                <div className={classnam ? "fileInputRm" : "fixed"}>
                    <div className="fileInput">
                        <p>Raport faylini yuklab junatish tugmasini bosing</p>
                        <label className="lableFIle">
                            <MdAttachFile /> {/* Display file icon */}
                            <span>{fileName}</span> {/* Display file name */}
                            <input type="file" onChange={handleFileChange} style={{display: "none"}} /> {/* File input */}
                        </label>
                        <button className='btnSendRoport' onClick={handleSubmit}>Jonatish</button> {/* Submit button */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Navbar() {
    // Theme
    const [theme, setTheme] = useState(false);

    useEffect(() => {
        const storedTheme = JSON.parse(localStorage.getItem("theme"));
        if (storedTheme != null) {
            setTheme(storedTheme);
            document.body.classList.toggle("dark", storedTheme);
        }
    }, []);

    const handleClick = () => {
        const newTheme = !theme;
        setTheme(newTheme);
        localStorage.setItem("theme", JSON.stringify(newTheme));
        document.body.classList.toggle("dark", newTheme);
    }

    // User info
    const userID = localStorage.getItem('userID')
    const token = localStorage.getItem('accessToken');
    const [userInfos, setUserInfos] = useState([])

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await axios.get(`https://manager.zafarr.uz/routers/userprofile/${userID}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                });
                setUserInfos(response.data);
            } catch (error) {
                console.error('Error fetching user information:', error);
            }
        };
        getUserInfo();
    }, [userID, token]);

    return (
        <div className="navbar">
            <div className="navBirga">
                <div className="navleft">
                    <Link to={'/'}><img src={rmIcon} alt="" /></Link>
                    <h3>Ranch Meneger</h3>
                </div>
                <div className="Theme">
                    <div className="toggleWrapper">
                        <input type="checkbox" className="dn" id="dn" />
                        <label onClick={handleClick} htmlFor="dn" className="toggle">
                            <span className="toggle__handler"></span>
                        </label>
                    </div>
                </div>
                <PostRaport token={token} userInfos={userInfos} />
            </div>
            <div className="navRight">
                <Link to={'/Profil'}>
                    {userInfos.map(item => (
                        <button className="userInfo" key={item.id}>
                            <img src={item.image_url} alt="" />
                            <h5>{item.first_name || item.username} {item.last_name || item.username}</h5>
                        </button>
                    ))}
                </Link>
            </div>
        </div>
    );
}
