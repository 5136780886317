/* eslint-disable react/jsx-key */
import { BsPlusLg } from 'react-icons/bs'
// import './taskInfo.css'
import { useEffect, useRef, useState } from 'react'
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { Dropdown } from '@mui/base/Dropdown';
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import List from './UserList'
import { IoCloseSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";



export default function TaskInfo() {

    const { id } = useParams()
    const [tasksChil, setTasksChil] = useState([])

    const getTask = async () => {
        const response = await axios.get(`https://manager.zafarr.uz/routers/lists/${id}`)
        setTasksChil(response.data)
    }

    useEffect(() => {
        getTask()
    }, [])

    // const userID = localStorage.getItem('userID');
    const [userData, setUserData] = useState([])
    const getUserInfo = async () => {
        try {
            const response = await axios.get(`https://manager.zafarr.uz/users/${userID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${tokenw}`,
                },
            });
            console.log(response.data);
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user information:', error);
            // Handle errors if necessary
        }
    };

    useEffect(() => {
        getUserInfo();
    }, []);


    // const [userData, setUserData] = useState([])
    console.log(userData);

    const top100Films = [
        { label: 'Muhammad Komilov', year: 1994 },
        { label: 'Lionel Ranaldo', year: 1972 },
    ]

    const inputRef = useRef()
    const taskNameRef = useRef()
    const closeRef = useRef()
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const setRef = useRef()
    const [editingTaskName, setEditingTaskName] = useState(""); // Yangi o'zgaruvchi
    const changeRef = useRef()


    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const imageUrl = event.target.result;
                setSelectedFile(file);
                setImageSrc(imageUrl);
            }
        }
    };


    // list add

    const tokenw = localStorage.getItem('accessToken');

    const addList = async () => {
        try {
            const response = await axios.post(
                `https://manager.zafarr.uz/routers/list/`,
                {
                    title: taskNameRef.current.value,
                    board: id
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Token ${tokenw}`,
                    },
                }
            );
            console.log(response.data);
            window.location.reload()
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // add user board 

    const addUserBoard = async (itemID) => {
        try {
            const response = await axios.post(
                `https://manager.zafarr.uz/invite_user/${id}/boards/`,
                {
                    user_id: itemID
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Token ${tokenw}`,
                    },
                }
            );
            console.log(response.data);
            window.location.reload()
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const userID = localStorage.getItem('userID')

    const addOtdel = async () => {
        try {
            const response = await axios.post(`https://manager.zafarr.uz/invite_dep_to_board/${id}/boards/`, {
                user_id: userID
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Token ${tokenw}`,
                    },
                })
            window.location.reload()
        } catch (error) {
            console.error("add bo'lim xatolik", error)
        }
    }

    // board uusers
    const [boadUsers, setBoardUsers] = useState([])
    const [depRtament, setDepArtament] = useState([])


    const BoardUsers = async () => {
        try {
            const response = await axios.get(`https://manager.zafarr.uz/routers/boards/${id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Token ${tokenw}`,
                    },
                }
            )
            setBoardUsers(response.data.user)
            setDepArtament(response.data.departament)
        } catch {
            // console.log("Error:" , error);
        }
    }

    useEffect(() => {
        BoardUsers()
    }, [])

    const [isActive, setIsActive,] = useState(false);

    const toggleDropdown = () => {
        setIsActive(!isActive);
    };

    const [IsActiveTwo, setIsActiveTwo] = useState(false)

    const toggleDropdownTwo = () => {
        setIsActiveTwo(!IsActiveTwo);
    };

    // search
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // const filteredUsers = userData.filter(item =>
    //     item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     item.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    // );


    // Otdel

    const [otedelData, setOtdelData] = useState([])
    const getOtdel = async () => {
        const response = await axios.get(`https://manager.zafarr.uz/routers/all/departaments/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${tokenw}`,
            },
        })
        setOtdelData(response.data);
    }

    useEffect(() => {
        getOtdel()
    }, [])

    return (
        <>
            <div className="ProfileNav">
                <div className="taskStatusSelect">
                    {
                        userData.oddiy_admin == true ? (
                            <div className="userAdd">
                                <div className="BurgerUsers">
                                    <nav>
                                        <div class="navbarburg">
                                            <div class="containerburg nav-container">
                                                <input class="checkbox" type="checkbox" name="" id="" />
                                                <div class="hamburger-lines">
                                                    <span class="line line1"></span>
                                                    <span class="line line2"></span>
                                                    <span class="line line3"></span>
                                                </div>
                                                <div class="menu-User">
                                                    <div className="User_Spisok">
                                                        {
                                                            boadUsers.map(item => (
                                                                <div className="User_Spisok_Box">
                                                                    <div className="User_Spisok_Img_Box">
                                                                        <img src={item.image_url} alt="" />
                                                                    </div>
                                                                    <div className="User_Spisok_Text_Box">
                                                                        <div className="User_Spisok_NL">
                                                                            <div className="User_Spisok_N">
                                                                                <p>Ismi:</p>
                                                                                <h4>{item.first_name}</h4>
                                                                            </div>
                                                                            <div className="User_Spisok_L">
                                                                                <p>Familiya:</p>
                                                                                <h4>{item.last_name}</h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="User_Spisok_K">
                                                                            <p>Kasbi:</p>
                                                                            <h4>{item.kasbi}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="User_add_for">
                                                        {/* <div className="userAdd__userAdd">
                                                    <div className="dropdown_UserAdd">
                                                        <button className="dropbtn_UserAdd" onClick={toggleDropdown}><FaUser /></button>
                                                        <div className={`dropdown-content_UserAdd ${isActive ? 'active' : ''}`}>
                                                            <button className="dropbtn_UserAdd_close" onClick={toggleDropdown}><IoCloseSharp /></button>
                                                            <div className="InputUserAddPoisk">
                                                                <label htmlFor="search">
                                                                    <input type="search" placeholder='Ism kiriting yoki familya kriting:' onChange={handleSearch} />
                                                                    <button className='searchbutton'><FaSearch /></button>
                                                                </label>
                                                            </div>
                                                            <div className="DivAddUser_button_obsh">
                                                                {filteredUsers.map(item => (
                                                                    <button key={item.id} onClick={() => addUserBoard(item.id)} id='ButtonUsers'>
                                                                        <div className="imgAddUserButton">
                                                                            <img src={item.image_url} alt="" />
                                                                        </div>
                                                                        <div className="TextAddUserButton">
                                                                            <p>{item.last_name} {item.first_name}</p>
                                                                            <h6>{item.kasbi}</h6>
                                                                        </div>
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}
                                                        <div className="userAdd__userAdd">
                                                            <div className="dropdown_UserAdd">
                                                                <button className="dropbtn_UserAdd" onClick={addOtdel}><FaUserGroup /></button>
                                                                {/* <div className={`dropdown-content_UserAdd ${IsActiveTwo ? 'active' : ''}`}>
                                                            <button className="dropbtn_UserAdd_close" onClick={toggleDropdownTwo}><IoCloseSharp /></button>
                                                            <div className="InputUserAddPoisk">
                                                                <label htmlFor="search"><input type="search" placeholder="Bo'lim nomini kiriting:" /> <button className='searchbutton'><FaSearch /></button></label>
                                                            </div>
                                                            <div className="DivAddUser_button_obsh">
                                                                {otedelData.map(item => (
                                                                    <button key={item.id} onClick={() => addOtdel(item.id)} id='ButtonUsers'>
                                                                        <div className="TextAddUserButton">
                                                                            <h3>bolim nomi:</h3>
                                                                            <h4>{item.title}</h4>
                                                                        </div>
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <div className="tasks">
                    {
                        tasksChil.map((taskItem, index) => (
                            <List
                                key={taskItem.id}
                                inputRef={inputRef}
                                editingTaskName={editingTaskName}
                                closeRef={closeRef}
                                setEditingTaskName={setEditingTaskName}
                                taskItem={taskItem}
                                id={id}
                                changeRef={changeRef}

                            />
                        ))

                    }
                    {
                        userData.oddiy_admin == true ? (
                            <div className="taskAddBtn">
                                <div className="addMiniDesc" style={{ backgroundColor: "#F1F3F2" }}>
                                    <input ref={taskNameRef} type="text" placeholder='Add another list' />
                                    <button onClick={addList}><BsPlusLg /></button>
                                </div>
                            </div>
                        ) : null}
                </div >

            </div>
        </>
    )
}