import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { FaPlus } from "react-icons/fa6";
import './otdel.css';

export default function OtdelUser({ item, closeModal }) {
    return (
        <div className="profilInfos">
            <div className="profilInfosBox rel">
                <h2 className='OtedelName'>{item.title}</h2>
                <div className="userS">
                    {item.users.map(data =>
                        <div className="useR" key={data.id}>
                            <div className="Userimg">
                                <img src={data.image_url} alt="" />
                            </div>
                            <div className="UserP">
                                <div className="UserPOne">
                                    <div className="UserPName">
                                        <p>Ismi:</p>
                                        <h3>{data.first_name}</h3>
                                    </div>
                                    <div className="UserPLast">
                                        <p>Familiya:</p>
                                        <h3>{data.last_name}</h3>
                                    </div>
                                </div>
                                <div className="UserPTwo">
                                    <div className="UserPKsbi">
                                        <p>Kasbi:</p>
                                        <h3>{data.kasbi}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="profilInfosBox__userInfos">
                    <div className="btns">
                        <button className='closeBtn' onClick={closeModal}>Close</button> {/* Modalni yopish tugmasi */}
                    </div>
                </div>
            </div>
        </div>
    );
}
